import { useEffect, useState } from "react";
import AddServiceResquest1 from "./AddServiceResquest1";
import AddServiceResquest2 from "./AddServiceResquest2";
import AddServiceResquest3 from "./AddServiceResquest3";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SuccessIcon from "../../assets/images/success.png";
const AddServiceRequest = () => {
  const [step, setStep] = useState("First");
  const [serviceRequestUUID, setServiceRequestUUID] = useState("");
  const [countdown, setCountdown] = useState(9);
  const [imgFiles, setImgFiles] = useState<any[]>([]);
  const [show, setShow] = useState(false);  

  const handleClose = () => {
    setShow(false);
    localStorage.setItem("property_detail_tab", "tab4");
    window.history.back();
  };
  let location = useLocation();
  useEffect(() => {
    if (
      location?.state?.serviceReqUUID 
    ) {
      setServiceRequestUUID(location?.state?.serviceReqUUID);
    }
  }, [location?.state]);
  const handleInterval = () => {
    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  };
  const updateStatus = () => {
    return WebService.postAPI({
      action: `property/${serviceRequestUUID}/maintenance/status`,
      body: { status: "OPEN" },
    })
      .then((res: any) => {
        WebService.removeLoader("upload_image");
        setShow(true);
      })
      .catch((error) => {
        WebService.removeLoader("upload_image");
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (show) {
      if (countdown >= 1) {
        handleInterval();
      } else {
        handleClose();
      }
    }
  }, [show, countdown]);
  const getImageFiles = (mentainanceId: any) => {
    return WebService.getAPI({
      action: `property/${mentainanceId}/maintenance/asset`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        const newFiles = res?.assets.map((image: any) => {
          const blob = new Blob([image], { type: image.type });

          const file1 = new File([blob], image.title, { type: image.type });
          return {
            file: file1,
            localAddress: image.file,
            has_covered_image: image.has_covered_image,
            existing: true,
            id: image.id,
          };
        });
        setImgFiles(newFiles);
        if (newFiles.length === 0) {
          setStep("Second");
        } else {
          setStep("Third");
        }
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        return error;
      });
  };
  return (
    <>
      {step === "First" ? (
        <AddServiceResquest1
          serviceRequestUUID={serviceRequestUUID}
          handleNext={(uuid: any) => {
            setServiceRequestUUID(uuid);
            getImageFiles(uuid);
          }}
        />
      ) : step === "Second" ? (
        <AddServiceResquest2
          imgFiles={imgFiles}
          handleBack={() => {
            setStep("First");
          }}
          handleNext={(imgFile: any) => {
            if (imgFile && imgFile.length > 0) {
              setImgFiles((prevFiles: any) => [...prevFiles, ...imgFile]);
              setStep("Third");
            } else {
              updateStatus();
            }
          }}
        />
      ) : step === "Third" ? (
        <AddServiceResquest3
          serviceRequestUUID={serviceRequestUUID}
          ImageFiles={imgFiles}
          handleBack={(files: any) => {
            if (files.length === 0) {
              setImgFiles([]);
              setStep("Second");
            } else setStep("First");
          }}
          handleNext={(imgFile: any) => {
            setImgFiles(imgFiles);
            setStep("Second");
          }}
        />
      ) : (
        ""
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center">
            <img src={SuccessIcon} width={60} className="mb-3" alt="" />
            <h2 className="font-18 font-semibold">
              {window.location.href.includes("edit-")
                ? "Service Request Updated Successfully!"
                : "Service Request Added Successfully!"}
            </h2>
            <p className="text-secondary-dark">
              You will be redirected in {countdown} seconds..
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddServiceRequest;
