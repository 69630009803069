import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  Container,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { AiTwotoneEdit } from "react-icons/ai";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";
import { Label } from "../Common/Label/Label";
import PageLoader from "../Common/Loader/PageLoader";
import PhoneInput from "react-phone-input-2";
import HelperService from "../../Services/HelperService";
import "react-phone-input-2/lib/style.css";
import CompoLoader from "../Common/ComponentLoader/CompoLoader";
import { PiCaretLeftBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { addOrganization, profileSettings } from "../../config/action";

const SelectOrganization = () => {
  let dispatch = useDispatch();
  const [orgList, setOrgList] = useState<any[]>([]);
  let navigate = useNavigate();
  const [section, setSection] = useState("First");
  const [newOrgFlag, setOrgFlag] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [key, setKey] = useState(Math.random());
  let companyObject = {
    id: "",
    uuid: "",
    name: "",
    email: "",
    dial_code: "+91",
    country_code: "in",
    mobile_number: "",
  };
  const [company, setCompany] = useState<any>(companyObject);
  const [loader, setLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  
const profileDetails = useSelector(
  (state: any) => state?.profile?.profile_setting
);

  useEffect(() => {
    getOrganizationList();
  }, []);

  const getOrganizationList = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `user-organization`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setOrgList(res?.list);
        // setSelectedId(res?.list[0]?.uuid)
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        return error;
      });
  };
  const goBack = () => {
    setCompany(companyObject);
    setSection("First");
  };
  const handleSaveNewOrganization = () => {
    if (
      company?.name === "" ||
      company?.email === "" ||
      !HelperService.validateEmail(company?.email) ||
      company?.mobile_number === ""
    ) {
      setOrgFlag(true);
      return;
    } else {
      setOrgFlag(false);
    }

    console.log(company, "company");
    setAddLoader(true);
    WebService.addLoader("add-new");
    return WebService.postAPI({
      action: `organization`,
      body: company,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        getOrganizationList();
        setSection("First");
        setCompany(companyObject);
        // meCall();
        WebService.removeLoader("add-new");
        setAddLoader(false);
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        WebService.removeLoader("add-new");
        setAddLoader(false);
        return error;
      });
  };
  const handleUpdateOrganization = () => {
    if (
      company?.name === "" ||
      company?.email === "" ||
      company?.email === null ||
      !HelperService.validateEmail(company?.email) ||
      company?.mobile_number === "" ||
      company?.mobile_number === null
    ) {
      setOrgFlag(true);
      return;
    } else {
      setOrgFlag(false);
    }

    setAddLoader(true);
    WebService.addLoader("add-new");
    return WebService.putAPI({
      action: `organization/${selectedId}`,
      body: company,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        getOrganizationList();
        setSection("First");
        setCompany(companyObject);
        meCall("");
        WebService.removeLoader("add-new");
        setAddLoader(false);
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        WebService.removeLoader("add-new");
        setAddLoader(false);
        return error;
      });
  };
  const getSubscriptionStatus = () => {
    if (
      !selectedId ||
      (profileDetails?.organizations &&
        profileDetails?.organizations?.length > 0 &&
        profileDetails?.organizations[0]?.id) === selectedId
    ) {
      navigate("/dashboard");
      return;
    }

    WebService.addLoader("dashboard-btn");
    return WebService.getAPI({
      action: `subscription-status`,
      body: null,
      isShowError: false,
    })
      .then((res: any) => {
        if (res?.have_subscription === false) {
          navigate("/subscription/plans");
          WebService.removeLoader("dashboard-btn");
          return;
        }
        if (
          res?.user_type === "SUPER_ADMIN_EMPLOYEE" ||
          res?.user_type === "SUPER_ADMIN"
        ) {
          WebService.removeLoader("dashboard-btn");
          return;
        }
        if (
          res?.plan_status === "APPROVED" &&
          res?.have_subscription === true
        ) {
          meCall("plan");
          return;
        }
        // else if (
        //   res?.plan_status === "EXPIRED" &&
        //   res?.have_subscription === true
        // ) {
        //   navigate("/subscription/plans");
        //   return;
        // }
        else if (
          res?.plan_status === "PENDING" &&
          res?.have_subscription === true
        ) {
          navigate("/subscription/plans/request-sent");
          WebService.removeLoader("dashboard-btn");
          return;
        } else if (
          res?.plan_status === "REJECTED" &&
          res?.have_subscription === true
        ) {
          WebService.removeLoader("dashboard-btn");
          navigate("/subscription/plans");
          return;
        }
      })
      .catch((error: any) => {
        return error;
      });
  };
  const getOrgDetails = (uuid: any) => {
    return WebService.getAPI({
      action: `organization/${uuid}/info`,
      body: null,
      isShowError: false,
    })
      .then((res: any) => {
        setSection("Second");
        setCompany(res?.info);
        setKey(Math.random());
      })
      .catch((error: any) => {
        return error;
      });
  };
  const meCall = (type:any) => {
    return WebService.getAPI({
      action: `me/${selectedId}`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        let tempArray: any = [];
        res?.info?.organizations.forEach((obj: any) => {
          let values = {
            label: res?.info?.organizations?.name,
            value: res?.info?.organizations?.id,
          };
          tempArray.push(values);
        });

        localStorage.setItem("user_id", res?.info?.id);
        dispatch(addOrganization(tempArray));
        dispatch(profileSettings(res?.info));
        localStorage.setItem("login_type", res?.info?.login_type);
        localStorage.setItem("user_mode", res?.info?.user_type?.user_type);
        if (type === "plan") {
          navigate("/dashboard");
          WebService.removeLoader("dashboard-btn");
        }

      })
      .catch((error: any) => {
        WebService.removeLoader("dashboard-btn");
        return error;
      });
  };
  const removeDialCodeFromMobile = (mobileNumber: any, dialCode: any) => {
    if (
      typeof mobileNumber === "string" &&
      typeof dialCode === "string" &&
      dialCode.length > 0
    ) {
      if (mobileNumber.startsWith(dialCode)) {
        return mobileNumber.slice(dialCode.length);
      }
    }
    return mobileNumber;
  };
  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="mx-auto">
              {section === "First" ? (
                <Card className="login-card card-style p-4">
                  <div className=" ">
                    <div className="text-center mb-4 mb-lg-5">
                      <h1 className="mb-2 font-semibold font-18">
                        Select Organization
                      </h1>
                    </div>

                    <div className="">
                      <Form className="form-style">
                        <ToggleButtonGroup
                          type="radio"
                          name="AccountMode"
                          className="radio-card-option"
                          onChange={() => {}}
                          value={""}
                          // value={selectedAccountMode}
                          // onChange={handleAccountModeChange}
                        >
                          {orgList && orgList.length > 0
                            ? orgList.map((org: any, index: any) => {
                                return (
                                  <ToggleButton
                                    key={org.value}
                                    id={`tbg-btn-1_${org.id}`}
                                    value={org.id}
                                    className="btn btn-outline-light mb-2"
                                  >
                                    <div
                                      key={index}
                                      className="d-flex justify-content-between"
                                    >
                                      <Form.Check
                                        type={"radio"}
                                        defaultChecked={
                                          org.uuid ===
                                          localStorage?.getItem("org_uuid")
                                        }
                                        id={`default-radio_${index}`}
                                        label={org.name}
                                        name={"org"}
                                        className="font-16 me-3"
                                        onChange={(e: any) => {
                                          setSelectedId(org.uuid);
                                          localStorage.setItem(
                                            "org_uuid",
                                            org.uuid
                                          );
                                          localStorage.setItem(
                                            "organization",
                                            org.name
                                          );
                                        }}
                                      />
                                      <AiTwotoneEdit
                                        className="cursor-pointer"
                                        size={20}
                                        onClick={() => {
                                          setSelectedId(org.uuid);
                                          getOrgDetails(org.uuid);
                                        }}
                                      />
                                    </div>
                                  </ToggleButton>
                                );
                              })
                            : ""}
                        </ToggleButtonGroup>
                        <div className="d-flex justify-content-between mt-4">
                          {" "}
                          <Button
                            className="btn btn-brand me-1"
                            id="dashboard-btn"
                            onClick={() => {
                              getSubscriptionStatus();
                            }}
                          >
                            Go To Dashboard
                          </Button>
                          <Button
                            className="btn btn-brand ms-1"
                            id="verify"
                            onClick={() => {
                              setSection("Second");
                            }}
                          >
                            + Add New Organization
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Card>
              ) : (
                section === "Second" && (
                  <Form className="form-style">
                    <Card className="login-card card-style">
                      <Card.Header className=" ">
                        <div className="d-flex gap-2 align-items-center">
                          <button
                            className="btn header-nav-btn"
                            onClick={goBack}
                          >
                            <PiCaretLeftBold size={16} />
                          </button>

                          <h2 className="page-title mb-0">
                            {selectedId === "" ? "Add Company" : "Edit Company"}
                          </h2>
                        </div>
                        {/* <h1 className="font-semibold font-18">Add Company</h1> */}
                      </Card.Header>
                      <Card.Body>
                        <Row className=" position-relative">
                          {addLoader ? <CompoLoader /> : null}
                          <Col lg={6}>
                            <Form.Label className="required font-semibold font-14">
                              Company Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="name"
                              name="name"
                              value={company?.name}
                              placeholder="Enter Company Name"
                              onChange={(e: any) => {
                                setCompany((prev: any) => ({
                                  ...prev,
                                  name: e.target.value,
                                }));
                              }}
                            />
                            {newOrgFlag && company?.name === "" && (
                              <Label
                                title="Please enter company name"
                                modeError={true}
                              ></Label>
                            )}
                          </Col>
                          <Col lg={6}>
                            <Form.Label className="required font-semibold font-14">
                              Contact Email
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="email"
                              value={company?.email}
                              name="email"
                              placeholder="Enter Email"
                              onChange={(e: any) => {
                                setCompany((prev: any) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                              }}
                            />
                            {(!HelperService.validateEmail(company?.email) ||
                              company?.email === "") &&
                              newOrgFlag && (
                                <Label
                                  title="Please enter valid email"
                                  modeError={true}
                                ></Label>
                              )}
                          </Col>
                          <Col lg={6} className="mt-2">
                            <Form.Label className="required font-semibold font-14">
                              Contact Mobile Number
                            </Form.Label>
                            <PhoneInput
                              key={key}
                              country={company?.country_code}
                              value={
                                company?.dial_code
                                  ? `${company.dial_code}${company.mobile_number}`
                                  : `+91${company?.mobile_number}`
                              }
                              onChange={(phone, country: any) => {
                                let mobile = removeDialCodeFromMobile(
                                  phone,
                                  country.dialCode
                                );
                                setCompany((prev: any) => ({
                                  ...prev,
                                  dial_code: country?.dialCode,
                                  mobile_number: mobile,
                                  country_code: country?.countryCode,
                                }));
                              }}
                            />
                            {newOrgFlag &&
                              (company?.mobile_number === "" ||
                                company?.mobile_number === null) && (
                                <Label
                                  title="Please enter mobile number"
                                  modeError={true}
                                ></Label>
                              )}
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-brand w-100 mt-4"
                            id="add-new"
                            onClick={() => {
                              if (selectedId === "")
                                handleSaveNewOrganization();
                              else handleUpdateOrganization();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Form>
                )
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default SelectOrganization;
