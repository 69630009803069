import { combineReducers } from '@reduxjs/toolkit';
import { GET_ORGANIZATION_LIST, LOGOUT_REQUEST ,INVOICE_TYPE_REQUEST, ADD_ACCOUNT_EXPENSE,MODE } from '../config/action';

const initialState = {
  organizations:[],
  invoice_type:"",
  account_expense:{}
};

export const organizationReducer = (state = initialState, action:any) => {
 
  switch (action.type) {
    case GET_ORGANIZATION_LIST:
      return {
        ...state,
        organizations: action.payload,
      };
    case INVOICE_TYPE_REQUEST:
      return {
        ...state,
        invoice_type: action.payload,
      };
      case ADD_ACCOUNT_EXPENSE:
      return {
        ...state,
        account_expense: action.payload,
      };
      case MODE:
      return {
        ...state,
        mode: action.payload,
      };
    default:
      return state;
  }
};
// export const logoutRequest = (state = initialState, action:any) => {
 
//   switch (action.type) {
//     case LOGOUT_REQUEST:
//       return {
//         ...state,
//         organizations: ,
//       };
//     default:
//       return state;
//   }
// };

// export default organizationReducer;

// = combineReducers({
//   organizations: organizationReducer
// });