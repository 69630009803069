import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, ProgressBar, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import deleteIcon from "../../assets/images/delete-icon.svg";
import SuccessIcon from "../../assets/images/success.png";
import WebService from "../../Services/WebService";
import CompoLoader from "../Common/ComponentLoader/CompoLoader";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import InnerHeader from "../InnerHeader";
interface PropsData {
  handleNext: any;
  handleBack: any;
  ImageFiles: any;
  serviceRequestUUID: any;
}

const AddServiceResquest3 = (props: PropsData) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    localStorage.setItem("property_detail_tab", "tab4");
    window.history.back();
  };
  const [files, setFiles] = useState<any[]>([]);
  const [compoLoader, setCompoLoader] = useState(false);
  const [countdown, setCountdown] = useState(9);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(0);
  const [action, setAction] = useState("");
  useEffect(() => {
    setFiles(props.ImageFiles);
  }, []);
  const handleInterval = () => {
    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  };
  useEffect(() => {
    if (show) {
      if (countdown >= 1) {
        handleInterval();
      } else {
        handleClose();
      }
    }
  }, [show, countdown]);

  const handleChangeInImageName = (value: any, index: any) => {
    const updatedFiles = [...files]; // Create a copy of the files array
    const file = updatedFiles[index].file;
    const updatedFile = new File([file], value, { type: file.type }); // Create a new File object with the updated name
    updatedFiles[index].file = updatedFile; // Update the file in the copied array
    setFiles(updatedFiles); // Update the state with the modified files array
  };

  const handleCloseModal = (flag: any) => {
    if (flag) {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter(
          (_, idx) => idx !== selectedDeleteIndex
        );
        return updatedFiles;
      });
     

      if (files.length === 1) {
        props.handleBack([]);
      }
    }
    setShowDeleteModal(false);
  };
  const handleMakeCoverPhoto = (index: any) => {
    const newData = [...files];
    newData[index] = {
      ...newData[index],
      has_covered_image: 1,
    };
    setFiles(newData);
  };
  const handleDeleteImage = (index: any) => {
    if (files[index].id !== "") {
      setAction(
        `property/${props?.serviceRequestUUID}/maintenance/delete/${files[index].id}`
      );
    }
    setShowDeleteModal(true);
    setSelectedDeleteIndex(index);
  };

  async function handleSaveImages() {
    WebService.addLoader("upload_image");
    setCompoLoader(true);
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].id === "") {
          let body = {
            title: files[i].file.name,
            type: "IMAGE",
          };

          // Upload image with delay
          await uploadImageWithDelay(
            files[i].file,
            body,
            i + 1 === files.length,
            2000
          ); // 1000ms (1 second) delay
        }
      }
    }
    updateStatus();
  }
  const updateStatus = () => {
    return WebService.postAPI({
      action: `property/${props?.serviceRequestUUID}/maintenance/status`,
      body: { status: "OPEN" },
    })
      .then((res: any) => {
        WebService.removeLoader("upload_image");
        setCompoLoader(false);
        setShow(true);
      })
      .catch((error) => {
        WebService.removeLoader("upload_image");
        setCompoLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // A function to introduce a delay using setTimeout
  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Modified uploadImage to include delay
  async function uploadImageWithDelay(
    file: any,
    body: any,
    lastfileFlag: any,
    delayDuration: number
  ) {
    // Wait for the delay before uploading the image
    await delay(delayDuration);

    console.log("uploading", file, "time", new Date());

    return await WebService.uploadAPI({
      action: `property/asset/${props?.serviceRequestUUID}/maintenance`,
      body: null,
      _file: file,
      key: "file",
      _body: body,
    })
      .then((res: any) => {})
      .catch((error: any) => {
        return error;
      });
  }

  return (
    <>
      <div className="layout-veritcle-menu">
        <InnerHeader
          pageTitle={
            window.location.href.includes("edit-")
              ? "Edit Request"
              : "Add Request"
          }
          backArrow={false}
          closeBtn={true}
          closeUrl=""
          HelpBtn={true}
        />

        <div className="app-body no-left-menu pb-lg-0 pb-5 mb-4 ">
          <div className="app-page page-properties property-detail pb-3 mb-5 ">
            <Row className=" justify-content-center ">
              <Col lg="8">
                <Row className="align-items-center mb-4">
                  <Col lg={8}>
                    <h2 className="font-18 font-semibold mb-0">Add Photos</h2>
                  </Col>
                  <Col lg={4} className="text-end ">
                    <Link
                      to="javascript:void(0)"
                      className="font-14 font-medium"
                      onClick={() => {
                        props.handleNext(files);
                      }}
                    >
                      <BsPlusLg size={16} /> Add More Photos
                    </Link>
                  </Col>
                </Row>

                <div className="d-lg-flex gap-3 flex-wrap position-relative">
                  {compoLoader ? <CompoLoader /> : ""}
                  {props.ImageFiles &&
                    files.length > 0 &&
                    files.map((file: any, index: any) => {
                      return (
                        <div>
                          <Card className="photos-card">
                            {/* <Dropdown
                                align="end"
                                autoClose="outside"
                                className="action-dropdown  no-down-arrow"
                              > */}
                            {/* <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id=""> More </Tooltip>}
                                >
                                  <Dropdown.Toggle
                                    className="btn btn-outline-light"
                                    id="dropdown-basic"
                                  >
                                    <BsThreeDotsVertical
                                      size={16}
                                      className="text-secondary"
                                    />
                                  </Dropdown.Toggle>
                                </OverlayTrigger>
                                <Dropdown.Menu> */}
                            <div className="action-btns">
                              <Button
                                className="btn-outline-light delete-btn"
                                onClick={() => {
                                  handleDeleteImage(index);
                                }}
                              >
                                <img src={deleteIcon} width="16" />
                              </Button>
                              {/* </Dropdown.Menu>
                              </Dropdown> */}
                            </div>
                            <img
                              src={file.localAddress}
                              className="photo"
                              alt=""
                            />
                            <Card.Footer>
                              <input
                                type="text"
                                className="form-control"
                                value={file.file.name}
                                onChange={(e: any) => {
                                  handleChangeInImageName(
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </Card.Footer>
                          </Card>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </div>
          <div className="form-footer">
            <ProgressBar now={55} />
            <div className="footer-wrap">
              <div className="d-flex justify-content-between align-items-center">
                <div className="w-25">
                  <Link
                    to="javascript:void(0)"
                    className="text-nowrap"
                    onClick={() => {
                      props.handleBack("Back");
                    }}
                  >
                    <BiArrowBack size={16} className="me-1" />
                    Back
                  </Link>
                </div>
                <div className="col-lg-6 col-9 text-end d-flex gap-2 justify-content-end">
                  <Button
                    onClick={() => {
                      handleSaveImages();
                    }}
                    className="btn btn-brand"
                    id="upload_image"
                  >
                    Add Request
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center">
            <img src={SuccessIcon} width={60} className="mb-3" alt="" />
            <h2 className="font-18 font-semibold">
              {window.location.href.includes("edit-")
                ? "Service Request Updated Successfully!"
                : "Service Request Added Successfully!"}
            </h2>
            <p className="text-secondary-dark">
              You will be redirected in {countdown} seconds..
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <DeleteModal
        action={action}
        closeModal={(flag: any) => {
          handleCloseModal(flag);
        }}
        show={showDeleteModal}
      />
    </>
  );
};

export default AddServiceResquest3;
